import { AxiosRequestConfig, AxiosResponseHeaders, Method } from "axios";
import { Endpoints } from "./endpoints";
import { Request } from "./service";

type ApiResponse<T = any> = {
  success: boolean;
  message: string;
  statusCode: number;
  data: T;
  headers: AxiosResponseHeaders;
};

export type RequestOptions = AxiosRequestConfig & { urlParams?: object };

const gen = (params: string, baseURL = process.env.REACT_APP_BASE_URL) => {
  let url = params;
  let method: Method = "GET";

  const paramsArray = params.split(" ");
  if (paramsArray.length === 2) {
    method = paramsArray[0] as Method;
    url = paramsArray?.[1];
  }

  return (data: any, options: RequestOptions = {}) => {
    return Request(url, {
      data: method === "GET" ? null : options.data,
      method,
      params: options?.params || (method === "GET" ? data : null),
      baseURL: options?.baseURL || baseURL,
      headers: options?.headers,
      urlParams: options?.urlParams,
      responseType: options?.responseType,
    });
  };
};

type APIMap = {
  [key in keyof typeof Endpoints]: <T>(
    data?: any,
    option?: RequestOptions
  ) => Promise<ApiResponse<T>>;
};

const ApiObj = {};
for (const key in Endpoints) {
  // @ts-ignore
  ApiObj[key] = gen(Endpoints[key]);
}

const Api = ApiObj as APIMap;
export { Api };
