import { DashboardSliceType } from "@/types";
import { createSlice } from "@reduxjs/toolkit";
import { getCampaignResult } from "./thunk";

const initialState: DashboardSliceType = {
  campaignResultList: [],
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCampaignResult.fulfilled, (state, { payload }) => {
      state.campaignResultList = payload;
    });
  },
});

export const dashboardReducer = dashboardSlice.reducer;
