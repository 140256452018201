import { UserSliceType } from "@/types";
import { createSlice } from "@reduxjs/toolkit";
import { getUsers } from "./thunk";

const initialState: UserSliceType = {
  userList: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.userList = payload;
    });
  },
});

export const userReducer = userSlice.reducer;
