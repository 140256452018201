import { useAppDispatch, useAppSelector } from "@/app";
import { BreadCrumb, TableContainer } from "@/components";
import { PATH_NAME, StatusOptions, SubscriptionOptions } from "@/constants";
import { getUsers } from "@/features";
import { OptionProps, UserType } from "@/types";
import { ColumnDef } from "@tanstack/react-table";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import Flatpickr from "react-flatpickr";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { DeleteUser } from "./components/DeleteUser";
import { EADisableUser } from "./components/EADisableUser";
import { FilterProps } from "./types";

const UserManagement = () => {
  const { userList } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<UserType[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [trigger, setTrigger] = useState<number | null>(null);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [filter, setFilter] = useState<FilterProps>({
    combineKey: "",
    subscription: null,
    status: null,
    fromDate: "",
    toDate: "",
  });

  const columns = useMemo<ColumnDef<UserType>[]>(
    () => [
      {
        header: "Email",
        accessorKey: "email",
        disableFilters: true,
        enableColumnFilter: false,
      },
      {
        header: "Subscription Name",
        accessorKey: "subscription.package_name",
        disableFilters: true,
        enableColumnFilter: false,
      },
      {
        header: "Confirmed Date",
        accessorKey: "subscription.created_at",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) =>
          moment(row.original.subscription?.created_at).format("DD/MM/YYYY"),
      },
      {
        header: "User Type",
        accessorKey: "user_type",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <div className="text-capitalize">
              <Badge color="primary" style={{ fontSize: 13 }}>
                {row.original.user_type}
              </Badge>
            </div>
          );
        },
      },
      {
        header: "Expiry Date",
        accessorKey: "subscription.expose_expire_date",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return row.original.subscription?.expose_expire_date
            ? moment(row.original.subscription?.expose_expire_date).format(
                "DD/MM/YYYY"
              )
            : "";
        },
      },
      {
        header: "Status",
        accessorKey: "subscription.status_name",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => (
          <Badge
            color={
              row.original.subscription?.status_name?.toLowerCase() === "active"
                ? "success"
                : "danger"
            }
            style={{ fontSize: 13 }}
          >
            {row.original.subscription?.status_name}
          </Badge>
        ),
      },
      {
        header: "Action",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <div>
              <Button
                color="primary"
                className="btn-sm"
                onClick={() => {
                  setSelectedId(row.original.id);
                  setIsOpen(true);
                  if (row.original.is_active) {
                    setIsDisable(true);
                  } else {
                    setIsDisable(false);
                  }
                }}
              >
                {row.original.is_active ? "Disable" : "Enable"}
              </Button>
              <Button
                color="primary"
                className="btn-sm ms-2"
                style={{ minWidth: 58 }}
                onClick={() =>
                  navigate(
                    `${PATH_NAME.USER_MANAGEMENT}/edit/${row.original.id}`
                  )
                }
              >
                Edit
              </Button>
              <Button
                color="danger"
                className="btn-sm ms-2"
                style={{ minWidth: 58 }}
                onClick={() => {
                  setSelectedId(row.original.id);
                  setIsDelete(true);
                }}
              >
                Delete
              </Button>
              <Button
                color="primary"
                className="btn-sm ms-2"
                style={{ minWidth: 58 }}
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_REDIRECT_URL}?token=${row.original.sign_in_token}&type=mock`,
                    "_blank"
                  )
                }
              >
                Sign In As User
              </Button>
            </div>
          );
        },
      },
    ],
    [navigate]
  );

  const onApply = () => {
    let filteredData = userList;

    if (filter.combineKey) {
      filteredData = filteredData.filter((item: UserType) =>
        item.email.toLowerCase().includes(filter.combineKey.toLowerCase())
      );
    }

    if (filter.subscription) {
      filteredData = filteredData.filter(
        (item: UserType) =>
          item.subscription?.package_name?.toLowerCase() ===
          filter.subscription?.value?.toLowerCase()
      );
    }

    if (filter.status) {
      filteredData = filteredData.filter((item: UserType) =>
        filter.status?.value?.toLowerCase() === "inactive"
          ? item.subscription?.status_name.toLowerCase() === "terminated"
          : item.subscription?.status_name.toLowerCase() ===
            filter.status?.value
      );
    }

    if (filter.fromDate) {
      filteredData = filteredData.filter((data: UserType) =>
        moment(filter.fromDate).isSameOrBefore(
          moment(data.subscription?.created_at)
        )
      );
    }

    if (filter.toDate) {
      filteredData = filteredData.filter((data: UserType) => {
        const expireDate = data.subscription?.expose_expire_date;
        if (!expireDate) return false;

        return moment(filter.toDate, "DD/MM/YYYY").isSameOrAfter(
          moment(expireDate)
        );
      });
    }

    setData(filteredData);
  };

  const onReset = () => {
    setFilter({
      combineKey: "",
      subscription: null,
      status: null,
      fromDate: "",
      toDate: "",
    });
    setData(userList);
  };

  useEffect(() => {
    setData(userList);
  }, [userList]);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch, trigger]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="User Management" />
          <Card>
            <CardBody>
              <div className="mb-3 text-end">
                <Button
                  color="primary"
                  onClick={() => navigate(PATH_NAME.CREATE_USER_MANAGEMENT)}
                >
                  <i className="ri-add-line me-2" />
                  Create New User
                </Button>
              </div>
              <Row>
                <Col xs={12} lg={4}>
                  <FormGroup>
                    <Label>Search</Label>
                    <div className="form-icon right">
                      <Input
                        placeholder="Search by email"
                        value={filter.combineKey}
                        onChange={(e) =>
                          setFilter({
                            ...filter,
                            combineKey: e.target.value,
                          })
                        }
                      />
                      <i className="ri-search-line" />
                    </div>
                  </FormGroup>
                </Col>
                <Col xs={12} lg={4}>
                  <FormGroup>
                    <Label>Subscription</Label>
                    <Select
                      options={SubscriptionOptions}
                      value={filter.subscription}
                      placeholder="Select subscription"
                      onChange={(item: OptionProps) => {
                        setFilter({
                          ...filter,
                          subscription: item,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} lg={4}>
                  <FormGroup>
                    <Label>Status</Label>
                    <Select
                      options={StatusOptions}
                      value={filter.status}
                      placeholder="Select status"
                      onChange={(item: OptionProps) => {
                        setFilter({
                          ...filter,
                          status: item,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} lg={4}>
                  <FormGroup>
                    <Label>From Date</Label>
                    <Flatpickr
                      className="form-control"
                      options={{
                        dateFormat: "d/m/Y",
                      }}
                      placeholder="Users was created from"
                      value={filter.fromDate}
                      onChange={(_, date) => {
                        console.log("from date", date);
                        setFilter({
                          ...filter,
                          fromDate: date,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} lg={4}>
                  <FormGroup>
                    <Label>To Date</Label>
                    <Flatpickr
                      className="form-control"
                      options={{
                        dateFormat: "d/m/Y",
                      }}
                      placeholder="Users was created to"
                      value={filter.toDate}
                      onChange={(_, date) => {
                        console.log("to date", date);
                        setFilter({
                          ...filter,
                          toDate: date,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} lg={3} className="mt-auto">
                  <FormGroup>
                    <Button color="primary" onClick={onApply}>
                      Apply
                    </Button>
                    <Button color="light" className="ms-2" onClick={onReset}>
                      Reset
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <h4 className="card-title mb-0 flex-grow-1">User List</h4>
            </CardHeader>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                customPageSize={10}
                tableClass="table-centered align-middle table-nowrap mb-0"
                theadClass="text-muted table-light"
                divClass="table-responsive"
              />
            </CardBody>
          </Card>
        </Container>
      </div>

      <EADisableUser
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        isDisable={isDisable}
        selectedId={selectedId as number}
        triggerRender={() => setTrigger(new Date().getTime())}
      />

      <DeleteUser
        isOpen={isDelete}
        toggle={() => setIsDelete(false)}
        selectedId={selectedId as number}
        triggerRender={() => setTrigger(new Date().getTime())}
      />
    </>
  );
};

export default UserManagement;
