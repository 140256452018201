import { useAppDispatch, useAppSelector } from "@/app";
import { getCampaignResult } from "@/features";
import { CampaignType } from "@/types";
import { formatCurrency } from "@/utils";
import moment from "moment";
import { useEffect, useState } from "react";
import { Container, Table } from "reactstrap";

const ReportCampaignResult = () => {
  const { campaignResultList } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<CampaignType[]>([]);
  const [total, setTotal] = useState({
    total_customer: 0,
    total_sales: 0,
    total_cost: 0,
    average_sales_per_customer: 0,
    profit_per_customer: 0,
  });

  useEffect(() => {
    dispatch(getCampaignResult());
  }, [dispatch]);

  useEffect(() => {
    const totalCustomer = campaignResultList.reduce(
      (sum, item) => sum + Number(item.total_customer),
      0
    );
    const totalSales = campaignResultList.reduce(
      (sum, item) => sum + parseFloat(item.total_sale.toString()),
      0
    );
    const totalCost = campaignResultList.reduce(
      (sum, item) => sum + parseFloat(item.total_cost.toString()),
      0
    );
    const averageSalesPerCustomer = campaignResultList.reduce(
      (sum, item) =>
        sum + parseFloat(item.average_spend_per_customer.toString()),
      0
    );
    const profitPerCustomer = campaignResultList.reduce(
      (sum, item) => sum + parseFloat(item.profit_per_customer.toString()),
      0
    );

    setTotal({
      total_customer: totalCustomer,
      total_sales: totalSales,
      total_cost: totalCost,
      average_sales_per_customer: averageSalesPerCustomer,
      profit_per_customer: profitPerCustomer,
    });

    setData(campaignResultList);
  }, [campaignResultList]);

  useEffect(() => {
    if (data.length === 0) return;

    setTimeout(() => {
      window.print();
    }, 2000);
  }, [data]);

  return (
    <Container fluid>
      <div>
        <p className="fw-bold fs-4 text-center">
          MARALYTICS - CAMPAIGN RESULT SUMMARY
        </p>
      </div>
      <div className="d-flex flex-row justify-content-around">
        <p className="fs-4">
          Total Sales:{" "}
          <span className="fw-bold">{formatCurrency(total.total_sales)}</span>
        </p>
        <p className="fs-4">
          Report Date:{" "}
          <span className="fw-bold">{moment().format("DD/MM/YYYY")}</span>
        </p>
      </div>
      <div className="table-responsive">
        <Table className="table-striped table-bordered align-middle table-nowrap mb-0">
          <thead className="table-light">
            <th scope="col">Name</th>
            <th scope="col">Type</th>
            <th scope="col">Start Date</th>
            <th scope="col">End Date</th>
            <th scope="col">Total Customer</th>
            <th scope="col">Total Sales</th>
            <th scope="col">Total Cost</th>
            <th scope="col">Average Sales Per Customer</th>
            <th scope="col">Profit Per Customer</th>
          </thead>
          <tbody>
            {data.map((item) => {
              return (
                <tr>
                  <td>{item.name}</td>
                  <td>{item.campaign_type.name}</td>
                  <td>{moment(item.start_date).format("DD/MM/YYYY")}</td>
                  <td>{moment(item.end_date).format("DD/MM/YYYY")}</td>
                  <td>{Number(item.total_customer)}</td>
                  <td>{formatCurrency(item.total_sale)}</td>
                  <td>{formatCurrency(item.total_cost)}</td>
                  <td>{formatCurrency(item.average_spend_per_customer)}</td>
                  <td>{formatCurrency(item.profit_per_customer)}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot className="table-light">
            <tr>
              <td colSpan={4} className="fw-bold">
                Total
              </td>
              <td className="fw-bold">{Number(total.total_customer)}</td>
              <td className="fw-bold">{formatCurrency(total.total_sales)}</td>
              <td className="fw-bold">{formatCurrency(total.total_cost)}</td>
              <td className="fw-bold">
                {formatCurrency(total.average_sales_per_customer)}
              </td>
              <td className="fw-bold">
                {formatCurrency(total.profit_per_customer)}
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </Container>
  );
};

export default ReportCampaignResult;
