import { Api } from "@/networking";
import { CampaignType } from "@/types";

export const getCampaignResultAPI = () => {
  return Api.GET_CAMPAIGN_RESULT_SUMMARY<CampaignType[]>("");
};

export const getTopSubscribersBySalesAPI = () => {
  return Api.GET_TOP_SUBSCRIBERS_BY_SALES<{ [k in string]: number }>("");
};

export const getTopSubscribersByProfitAPI = () => {
  return Api.GET_TOP_SUBSCRIBERS_BY_PROFIT<{ [k in string]: number }>("");
};

export const getTotalNewSubscribersAPI = () => {
  return Api.GET_TOTAL_NEW_SUBSCRIBERS<{ [k in string]: number }>("");
};

export const getTotalTerminatedSubscribersAPI = () => {
  return Api.GET_TOTAL_TERMINATED_SUBSCRIBERS<{ [k in string]: number }>("");
};

export const getTotalFreeTrialSubscribersAPI = () => {
  return Api.GET_TOTAL_FREE_TRIAL_SUBSCRIBERS<{ [k in string]: number }>("");
};

export const getTotalPaidSubscribersAPI = () => {
  return Api.GET_TOTAL_PAID_SUBSCRIBERS<{ [k in string]: number }>("");
};

export const getTotalDIYPaidSubscribersAPI = () => {
  return Api.GET_TOTAL_DIY_SUBSCRIBERS<{ [k in string]: number }>("");
};

export const getTotalIntegrationSubscribersAPI = () => {
  return Api.GET_TOTAL_INTEGRATION_SUBSCRIBERS<{ [k in string]: number }>("");
};

export const getTotalAgencySubscribersAPI = () => {
  return Api.GET_TOTAL_AGENCY_SUBSCRIBERS<{ [k in string]: number }>("");
};

export const getTotalAgencyClientsAPI = () => {
  return Api.GET_TOTAL_AGENCY_CLIENTS<{ [k in string]: number }>("");
};

export const getTotalInactiveSubscribersAPI = () => {
  return Api.GET_TOTAL_INACTIVE_SUBSCRIBERS<{ [k in string]: number }>("");
};

export const getTotalCampaignsFromSubscribersAPI = () => {
  return Api.GET_TOTAL_CAMPAIGNS_FROM_SUBSCRIBERS<{ [k in string]: number }>(
    ""
  );
};

export const getTotalCampaignSalesFromSubscribersAPI = () => {
  return Api.GET_TOTAL_CAMPAIGN_SALES_FROM_SUBSCRIBERS<{
    [k in string]: number;
  }>("");
};

export const getTotalCampaignProfitFromSubscribersAPI = () => {
  return Api.GET_TOTAL_CAMPAIGN_PROFIT_FROM_SUBSCRIBERS<{
    [k in string]: number;
  }>("");
};
