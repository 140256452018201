import { useAppDispatch } from "@/app";
import { PATH_NAME } from "@/constants";
import { login } from "@/features";
import { useFormik } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";

const Login = () => {
  const dispatch = useAppDispatch();

  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email")
        .required("Please fill out this field."),
      password: Yup.string().required("Please fill out this field."),
    }),
    onSubmit: (values) => {
      setError("");
      setLoader(true);

      dispatch(login(values))
        .unwrap()
        .catch((err) => setError(err.message))
        .finally(() => setLoader(false));
    },
  });

  return (
    <div
      className="auth-page-content bg-primary vh-100"
      style={{ paddingTop: 150 }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="mt-4">
              <CardBody className="p-4">
                <div className="text-center mt-2">
                  <h5 className="text-primary">Welcome Back !</h5>
                  <p className="text-muted">
                    Sign in to continue to Maralytics Admin.
                  </p>
                </div>
                {error && <Alert color="danger">{error}</Alert>}
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="mb-3">
                    <Label className="required">Email</Label>
                    <Input
                      name="email"
                      type="email"
                      placeholder="Enter email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <div className="float-end">
                      <Link
                        to={PATH_NAME.FORGOT_PASSWORD}
                        className="text-muted"
                      >
                        Forgot password?
                      </Link>
                    </div>
                    <Label className="required">Password</Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <Input
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password"
                        value={validation.values.password}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                      />
                      {validation.touched.password &&
                        validation.errors.password && (
                          <p className="text-danger mt-2">
                            {validation.errors.password}
                          </p>
                        )}
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none"
                        onClick={() => setShowPassword(!showPassword)}
                        type="button"
                        id="password-addon"
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </button>
                    </div>
                  </div>

                  {/* <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="auth-remember-check"
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="auth-remember-check"
                    >
                      Remember me
                    </Label>
                  </div> */}

                  <div className="mt-4">
                    <Button
                      color="primary"
                      disabled={loader && true}
                      className="w-100"
                      type="submit"
                    >
                      {loader && <Spinner size="sm" className="me-2" />}
                      Sign In
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>

            {/* <div className="mt-4 text-center">
              <p className="mb-0 text-white">
                Don't have an account ?{" "}
                <Link
                  to={PATH_NAME.REGISTER}
                  className="fw-semibold text-white text-decoration-underline"
                >
                  SignUp
                </Link>
              </p>
            </div> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
