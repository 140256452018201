import { PATH_NAME } from "@/constants";
import { useFormik } from "formik";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";

const ForgotPasswordPage = () => {
  const [error, setError] = useState("");

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid Email")
        .required("Please fill out this field."),
    }),
    onSubmit: (values) => {
      setError("");
    },
  });

  return (
    <Fragment>
      <div
        className="auth-page-content bg-primary vh-100"
        style={{ paddingTop: 150 }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2 mb-2">
                    <h5 className="text-primary">Forgot Password?</h5>
                    <p className="text-muted">Reset password with Maralytics</p>
                    <i className="ri-mail-send-line display-5 text-success mb-3"></i>
                  </div>
                  <div>
                    {error && <Alert color="danger">{error}</Alert>}
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-4">
                        <Label>Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email}
                        />
                        {validation.touched.email &&
                          validation.errors.email && (
                            <p className="text-danger mt-2">
                              {validation.errors.email}
                            </p>
                          )}
                      </div>

                      <div className="text-center mt-4">
                        <button className="btn btn-primary w-100" type="submit">
                          Reset Password
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p className="mb-0 text-white">
                  Return to{" "}
                  <Link
                    to={PATH_NAME.LOGIN}
                    className="fw-semibold text-white text-decoration-underline"
                  >
                    SignIn
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default ForgotPasswordPage;
