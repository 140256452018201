import { useAppDispatch, useAppSelector } from "@/app";
import { BreadCrumb, TableContainer } from "@/components";
import { PATH_NAME } from "@/constants";
import { getAgencies } from "@/features";
import { AgencyType } from "@/types";
import { ColumnDef } from "@tanstack/react-table";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader, Container } from "reactstrap";

const AgencyManagement = () => {
  const { agencyList } = useAppSelector((state) => state.agency);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const columns = useMemo<ColumnDef<AgencyType>[]>(
    () => [
      {
        header: "ID",
        accessorKey: "id",
        disableFilters: true,
        enableColumnFilter: false,
      },
      {
        header: "Name",
        accessorKey: "name",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <div
              onClick={() =>
                navigate(`${PATH_NAME.AGENCY_MANAGEMENT}/${row.original.id}`)
              }
              className="text-secondary text-decoration-underline cursor-pointer"
            >
              {row.original.name}
            </div>
          );
        },
      },
      {
        header: "Number of clients",
        accessorKey: "number_of_clients",
        disableFilters: true,
        enableColumnFilter: false,
      },
    ],
    [navigate]
  );

  useEffect(() => {
    dispatch(getAgencies());
  }, [dispatch]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Agency Management" />
        <Card>
          <CardHeader>
            <h4 className="card-title mb-0 flex-grow-1">Agency List</h4>
          </CardHeader>
          <CardBody>
            <TableContainer
              columns={columns || []}
              data={agencyList || []}
              customPageSize={10}
              tableClass="table-centered align-middle table-nowrap mb-0"
              theadClass="text-muted table-light"
              divClass="table-responsive"
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default AgencyManagement;
