import { useAppDispatch, useAppSelector } from "@/app";
import { LineCharts } from "@/components";
import {
  getCampaignResult,
  getTopSubscribersByProfit,
  getTopSubscribersBySales,
  getTotalAgencyClients,
  getTotalAgencySubscribers,
  getTotalCampaignProfitFromSubscribers,
  getTotalCampaignSalesFromSubscribers,
  getTotalCampaignsFromSubscribers,
  getTotalDIYPaidSubscribers,
  getTotalFreeTrialSubscribers,
  getTotalInactiveSubscribers,
  getTotalIntegrationSubscribers,
  getTotalNewSubscribers,
  getTotalPaidSubscribers,
  getTotalTerminatedSubscribers,
} from "@/features";
import { CampaignType, ChartDataProps } from "@/types";
import { formatCurrency } from "@/utils";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";

const ReportDashboard = () => {
  const { campaignResultList } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<CampaignType[]>([]);
  const [total, setTotal] = useState({
    total_customer: 0,
    total_sales: 0,
    total_cost: 0,
    average_sales_per_customer: 0,
    profit_per_customer: 0,
  });
  const [chartData, setChartData] = useState<ChartDataProps>({
    topSubscribersBySales: [],
    topSubscribersByProfit: [],
    totalNewSubscribers: [],
    totalTerminatedSubscribers: [],
    totalFreeTrialSubscribers: [],
    totalPaidSubscribers: [],
    totalDIYPaidSubscribers: [],
    totalIntegrationSubscribers: [],
    totalAgencySubscribers: [],
    totalAgencyClients: [],
    totalInactiveSubscribers: [],
    totalCampaignsFromSubscribers: [],
    totalCampaignSalesFromSubscribers: [],
    totalCampaignProfitFromSubscribers: [],
  });

  const loadCharts = useCallback(async () => {
    const data = await dispatch(getTopSubscribersBySales()).unwrap();
    const data1 = await dispatch(getTopSubscribersByProfit()).unwrap();
    const data2 = await dispatch(getTotalNewSubscribers()).unwrap();
    const data3 = await dispatch(getTotalTerminatedSubscribers()).unwrap();
    const data4 = await dispatch(getTotalFreeTrialSubscribers()).unwrap();
    const data5 = await dispatch(getTotalPaidSubscribers()).unwrap();
    const data6 = await dispatch(getTotalDIYPaidSubscribers()).unwrap();
    const data7 = await dispatch(getTotalIntegrationSubscribers()).unwrap();
    const data8 = await dispatch(getTotalAgencySubscribers()).unwrap();
    const data9 = await dispatch(getTotalAgencyClients()).unwrap();
    const data10 = await dispatch(getTotalInactiveSubscribers()).unwrap();
    const data11 = await dispatch(getTotalCampaignsFromSubscribers()).unwrap();
    const data12 = await dispatch(
      getTotalCampaignSalesFromSubscribers()
    ).unwrap();
    const data13 = await dispatch(
      getTotalCampaignProfitFromSubscribers()
    ).unwrap();

    setChartData({
      topSubscribersBySales: data,
      topSubscribersByProfit: data1,
      totalNewSubscribers: data2,
      totalTerminatedSubscribers: data3,
      totalFreeTrialSubscribers: data4,
      totalPaidSubscribers: data5,
      totalDIYPaidSubscribers: data6,
      totalIntegrationSubscribers: data7,
      totalAgencySubscribers: data8,
      totalAgencyClients: data9,
      totalInactiveSubscribers: data10,
      totalCampaignsFromSubscribers: data11,
      totalCampaignSalesFromSubscribers: data12,
      totalCampaignProfitFromSubscribers: data13,
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCampaignResult());
  }, [dispatch]);

  useEffect(() => {
    loadCharts();
  }, [loadCharts]);

  useEffect(() => {
    const totalCustomer = campaignResultList.reduce(
      (sum, item) => sum + Number(item.total_customer),
      0
    );
    const totalSales = campaignResultList.reduce(
      (sum, item) => sum + parseFloat(item.total_sale.toString()),
      0
    );
    const totalCost = campaignResultList.reduce(
      (sum, item) => sum + parseFloat(item.total_cost.toString()),
      0
    );
    const averageSalesPerCustomer = campaignResultList.reduce(
      (sum, item) =>
        sum + parseFloat(item.average_spend_per_customer.toString()),
      0
    );
    const profitPerCustomer = campaignResultList.reduce(
      (sum, item) => sum + parseFloat(item.profit_per_customer.toString()),
      0
    );

    setTotal({
      total_customer: totalCustomer,
      total_sales: totalSales,
      total_cost: totalCost,
      average_sales_per_customer: averageSalesPerCustomer,
      profit_per_customer: profitPerCustomer,
    });

    setData(campaignResultList);
  }, [campaignResultList]);

  useEffect(() => {
    if (data.length === 0) return;

    setTimeout(() => {
      window.print();
    }, 2000);
  }, [data]);

  return (
    <Container fluid>
      <div>
        <p className="fw-bold fs-4 text-center">
          MARALYTICS - DASHBOARD SUMMARY
        </p>
      </div>
      <div className="d-flex flex-row justify-content-around">
        <p className="fs-4">
          Total Sales:{" "}
          <span className="fw-bold">{formatCurrency(total.total_sales)}</span>
        </p>
        <p className="fs-4">
          Report Date:{" "}
          <span className="fw-bold">{moment().format("DD/MM/YYYY")}</span>
        </p>
      </div>
      <div className="table-responsive mb-5">
        <Table className="table-striped table-bordered align-middle table-nowrap mb-0">
          <thead className="table-light">
            <th scope="col">Name</th>
            <th scope="col">Type</th>
            <th scope="col">Start Date</th>
            <th scope="col">End Date</th>
            <th scope="col">Total Customer</th>
            <th scope="col">Total Sales</th>
            <th scope="col">Total Cost</th>
            <th scope="col">Average Sales Per Customer</th>
            <th scope="col">Profit Per Customer</th>
          </thead>
          <tbody>
            {data.map((item) => {
              return (
                <tr>
                  <td>{item.name}</td>
                  <td>{item.campaign_type.name}</td>
                  <td>{moment(item.start_date).format("DD/MM/YYYY")}</td>
                  <td>{moment(item.end_date).format("DD/MM/YYYY")}</td>
                  <td>{Number(item.total_customer)}</td>
                  <td>{formatCurrency(item.total_sale)}</td>
                  <td>{formatCurrency(item.total_cost)}</td>
                  <td>{formatCurrency(item.average_spend_per_customer)}</td>
                  <td>{formatCurrency(item.profit_per_customer)}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot className="table-light">
            <tr>
              <td colSpan={4} className="fw-bold">
                Total
              </td>
              <td className="fw-bold">{Number(total.total_customer)}</td>
              <td className="fw-bold">{formatCurrency(total.total_sales)}</td>
              <td className="fw-bold">{formatCurrency(total.total_cost)}</td>
              <td className="fw-bold">
                {formatCurrency(total.average_sales_per_customer)}
              </td>
              <td className="fw-bold">
                {formatCurrency(total.profit_per_customer)}
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>

      <Row>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">Top Subscribers by Sales</h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts data={chartData.topSubscribersBySales} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">Top Subscribers by Profit</h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts data={chartData.topSubscribersByProfit} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total New Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts data={chartData.totalNewSubscribers} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Terminated Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts data={chartData.totalTerminatedSubscribers} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Free Trial Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts data={chartData.totalFreeTrialSubscribers} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Paid Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts data={chartData.totalPaidSubscribers} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total DIY Paid Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts data={chartData.totalDIYPaidSubscribers} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Integration Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts data={chartData.totalIntegrationSubscribers} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Agency Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts data={chartData.totalAgencySubscribers} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Agency Clients per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts data={chartData.totalAgencyClients} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Inactive Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts data={chartData.totalInactiveSubscribers} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Campaigns from Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts data={chartData.totalCampaignsFromSubscribers} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Campaign Sales from Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts
                  data={chartData.totalCampaignSalesFromSubscribers}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Campaign Profit from Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts
                  data={chartData.totalCampaignProfitFromSubscribers}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ReportDashboard;
