import { Api } from "@/networking";
import { CreateUserFormType, UpdateUserFormType, UserType } from "@/types";

export const getUsersAPI = () => {
  return Api.GET_USERS<UserType[]>("");
};

export const getUserDetailsAPI = (id: number) => {
  return Api.GET_USER_DETAILS<UserType>("", {
    urlParams: {
      id,
    },
  });
};

export const createUserAPI = (data: CreateUserFormType) => {
  return Api.CREATE_USER<{}>("", {
    data,
  });
};

export const updateUserAPI = (data: UpdateUserFormType) => {
  const { id, ...rest } = data;

  return Api.UPDATE_USER<{}>("", {
    urlParams: {
      id,
    },
    data: rest,
  });
};

export const enableUserAPI = (id: number) => {
  return Api.ENABLE_USER<{}>("", {
    urlParams: {
      id,
    },
  });
};

export const disableUserAPI = (id: number) => {
  return Api.DISABLE_USER<{}>("", {
    urlParams: {
      id,
    },
  });
};

export const deleteUserAPI = (id: number) => {
  return Api.DELETE_USER<{}>("", {
    urlParams: {
      id,
    },
  });
};
