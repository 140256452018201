import { PATH_NAME } from "@/constants";
import {
  AgencyDetails,
  AgencyManagement,
  CAERedemptionManagement,
  CAESubscriptionManagement,
  CAEUserManagement,
  ChangePassword,
  Dashboard,
  ForgotPassword,
  Login,
  RedemptionManagement,
  Register,
  SubscriptionManagement,
  UserManagement,
  UserProfile,
  ViewRedemptionManagement,
} from "@/pages";
import { Navigate } from "react-router-dom";

const authProtectedRoutes = [
  { path: PATH_NAME.DASHBOARD, component: <Dashboard /> },
  { path: PATH_NAME.USER_MANAGEMENT, component: <UserManagement /> },
  {
    path: PATH_NAME.CREATE_USER_MANAGEMENT,
    component: <CAEUserManagement />,
  },
  {
    path: PATH_NAME.EDIT_USER_MANAGEMENT,
    component: <CAEUserManagement />,
  },
  { path: PATH_NAME.AGENCY_MANAGEMENT, component: <AgencyManagement /> },
  { path: PATH_NAME.AGENCY_DETAILS, component: <AgencyDetails /> },
  {
    path: PATH_NAME.SUBSCRIPTION_MANAGEMENT,
    component: <SubscriptionManagement />,
  },
  {
    path: PATH_NAME.CREATE_SUBSCRIPTION_MANAGEMENT,
    component: <CAESubscriptionManagement />,
  },
  {
    path: PATH_NAME.EDIT_SUBSCRIPTION_MANAGEMENT,
    component: <CAESubscriptionManagement />,
  },
  {
    path: PATH_NAME.REDEMPTION_MANAGEMENT,
    component: <RedemptionManagement />,
  },
  {
    path: PATH_NAME.CREATE_REDEMPTION_MANAGEMENT,
    component: <CAERedemptionManagement />,
  },
  {
    path: PATH_NAME.VIEW_REDEMPTION_MANAGEMENT,
    component: <ViewRedemptionManagement />,
  },
  {
    path: PATH_NAME.EDIT_REDEMPTION_MANAGEMENT,
    component: <CAERedemptionManagement />,
  },
  { path: PATH_NAME.PROFILE, component: <UserProfile /> },
  { path: PATH_NAME.CHANGE_PASSWORD, component: <ChangePassword /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to={PATH_NAME.DASHBOARD} />,
  },
  { path: "*", component: <Navigate to={PATH_NAME.DASHBOARD} /> },
];

const publicRoutes = [
  { path: PATH_NAME.LOGIN, component: <Login /> },
  { path: PATH_NAME.REGISTER, component: <Register /> },
  { path: PATH_NAME.FORGOT_PASSWORD, component: <ForgotPassword /> },
];

export { authProtectedRoutes, publicRoutes };
