import { loginAPI } from "@/services";
import { LoginFormType } from "@/types";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const login = createAsyncThunk(
  "auth/login",
  async (payload: LoginFormType) => {
    try {
      const { data } = await loginAPI(payload);
      return data.api_token;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);
