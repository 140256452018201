import { store } from "@/app";
import { authActions } from "@/features";
import { isNilOrEmpty } from "@/utils";
import axios, { AxiosResponse } from "axios";
import { cloneDeep } from "lodash";
import { compile } from "path-to-regexp";
import qs from "qs";
import { RequestOptions } from "./api";

const codeMessage = {
  400: "There was an error in the request, and the server did not create or modify data.",
  401: "The user does not have permissions.",
  403: "The user is authorized, but access is prohibited.",
  404: "The request was made for a record that does not exist, and the server did not perform an operation.",
  422: "When creating an object, a validation error occurred.",
  500: "A server error occurred. Please check the server.",
  502: "Gateway error.",
  503: "Services are unavailable and the server is temporarily overloaded or maintained.",
  504: "Gateway timed out.",
};

const errorCode = [400, 401, 403, 404, 422, 500, 502, 503, 504];

export const Request = async (url: string, options: RequestOptions) => {
  const {
    data,
    baseURL = process.env.REACT_APP_BASE_URL,
    headers = { "Content-Type": "application/json" },
    method,
    params,
    urlParams,
  } = options;

  const value = method === "GET" ? params : data;
  let cloneData;

  if (data instanceof FormData) {
    cloneData = data;
  } else {
    cloneData = cloneDeep(value);
  }

  try {
    let domain = "";
    const urlMatch = url.match(/[a-zA-z]+:\/\/[^/]*/);

    if (urlMatch) {
      [domain] = urlMatch;
      url = url.slice(domain.length);
    }

    url = compile(url)(urlParams);

    url = domain + url;
  } catch (e) {
    console.log({ e });
    //Show error
  }

  options.headers = {
    ...headers,
  };
  options.url = url;

  if (method === "GET") {
    options.params = cloneData;
    options.data = undefined;
  } else {
    options.params = params;
    options.data = cloneData;
  }

  options.baseURL = baseURL;

  options.paramsSerializer = function (params) {
    return qs.stringify(params, { arrayFormat: "repeat" });
  };

  return axios(options)
    .then((response: AxiosResponse) => {
      const { statusText, status, data, headers } = response;

      if (errorCode.includes(data.code)) {
        return Promise.reject({
          success: false,
          message: data.message,
          statusCode: data.code,
          data: data,
        });
      }

      const result: { [key: string]: any } = data?.data || data;

      const res = {
        success: true,
        message: statusText,
        statusCode: status,
        data: result,
        headers,
      };
      return Promise.resolve(res);
    })
    .catch((error: any) => {
      console.log({ axiosError: error });

      const { data, statusCode: stCode } = error;

      let msg;
      let statusCode;

      if (error) {
        statusCode = stCode;
        // @ts-ignore
        msg = data?.message || codeMessage[statusCode];
      } else {
        statusCode = 600;
        msg = error.message || "Network Error";
      }

      if (statusCode === 401) {
        // Handle logout
        store.dispatch(authActions.logOut());
      }

      if (statusCode === 403) {
        // history.push("/403");
      }

      if (statusCode <= 504 && statusCode >= 500) {
        // history.push("/500");
      }

      if (statusCode >= 404 && statusCode < 422) {
        // history.push("/404");
      }
      return Promise.reject({
        success: false,
        statusCode,
        message: msg,
      });
    });
};

axios.interceptors.request.use(
  (config) => {
    if (config.url?.indexOf("/token") !== -1) {
      return config;
    }

    const token = store.getState().auth.token;

    if (config.headers && typeof token === "string" && token !== "") {
      if (!isNilOrEmpty(token)) {
        config.headers.Authorization = token;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
