import {
  getCampaignResultAPI,
  getTopSubscribersByProfitAPI,
  getTopSubscribersBySalesAPI,
  getTotalAgencyClientsAPI,
  getTotalAgencySubscribersAPI,
  getTotalCampaignProfitFromSubscribersAPI,
  getTotalCampaignSalesFromSubscribersAPI,
  getTotalCampaignsFromSubscribersAPI,
  getTotalDIYPaidSubscribersAPI,
  getTotalFreeTrialSubscribersAPI,
  getTotalInactiveSubscribersAPI,
  getTotalIntegrationSubscribersAPI,
  getTotalNewSubscribersAPI,
  getTotalPaidSubscribersAPI,
  getTotalTerminatedSubscribersAPI,
} from "@/services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";

export const getCampaignResult = createAsyncThunk(
  "dashboard/getCampaignResult",
  async () => {
    try {
      const { data } = await getCampaignResultAPI();

      const formatData = data.map((dt) => {
        return {
          ...dt,
          start_date: dt.start_date ? moment(dt.start_date) : undefined,
          end_date: dt.end_date ? moment(dt.end_date) : undefined,
          profit_per_customer: dt.profit_per_customer
            ? Number(dt.profit_per_customer)
            : 0,
        };
      });
      return formatData;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const getTopSubscribersBySales = createAsyncThunk(
  "dashboard/getTopSubscribersBySales",
  async () => {
    try {
      const { data } = await getTopSubscribersBySalesAPI();
      const formattedData = Object.values(data) as number[];
      return formattedData;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const getTopSubscribersByProfit = createAsyncThunk(
  "dashboard/getTopSubscribersByProfit",
  async () => {
    try {
      const { data } = await getTopSubscribersByProfitAPI();
      const formattedData = Object.values(data) as number[];
      return formattedData;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const getTotalNewSubscribers = createAsyncThunk(
  "dashboard/getTotalNewSubscribers",
  async () => {
    try {
      const { data } = await getTotalNewSubscribersAPI();
      const formattedData = Object.values(data) as number[];
      return formattedData;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const getTotalTerminatedSubscribers = createAsyncThunk(
  "dashboard/getTotalTerminatedSubscribers",
  async () => {
    try {
      const { data } = await getTotalTerminatedSubscribersAPI();
      const formattedData = Object.values(data) as number[];
      return formattedData;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const getTotalFreeTrialSubscribers = createAsyncThunk(
  "dashboard/getTotalFreeTrialSubscribers",
  async () => {
    try {
      const { data } = await getTotalFreeTrialSubscribersAPI();
      const formattedData = Object.values(data) as number[];
      return formattedData;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const getTotalPaidSubscribers = createAsyncThunk(
  "dashboard/getTotalPaidSubscribers",
  async () => {
    try {
      const { data } = await getTotalPaidSubscribersAPI();
      const formattedData = Object.values(data) as number[];
      return formattedData;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const getTotalDIYPaidSubscribers = createAsyncThunk(
  "dashboard/getTotalDIYPaidSubscribers",
  async () => {
    try {
      const { data } = await getTotalDIYPaidSubscribersAPI();
      const formattedData = Object.values(data) as number[];
      return formattedData;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const getTotalIntegrationSubscribers = createAsyncThunk(
  "dashboard/getTotalIntegrationSubscribers",
  async () => {
    try {
      const { data } = await getTotalIntegrationSubscribersAPI();
      const formattedData = Object.values(data) as number[];
      return formattedData;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const getTotalAgencySubscribers = createAsyncThunk(
  "dashboard/getTotalAgencySubscribers",
  async () => {
    try {
      const { data } = await getTotalAgencySubscribersAPI();
      const formattedData = Object.values(data) as number[];
      return formattedData;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const getTotalAgencyClients = createAsyncThunk(
  "dashboard/getTotalAgencyClients",
  async () => {
    try {
      const { data } = await getTotalAgencyClientsAPI();
      const formattedData = Object.values(data) as number[];
      return formattedData;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const getTotalInactiveSubscribers = createAsyncThunk(
  "dashboard/getTotalInactiveSubscribers",
  async () => {
    try {
      const { data } = await getTotalInactiveSubscribersAPI();
      const formattedData = Object.values(data) as number[];
      return formattedData;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const getTotalCampaignsFromSubscribers = createAsyncThunk(
  "dashboard/getTotalCampaignsFromSubscribers",
  async () => {
    try {
      const { data } = await getTotalCampaignsFromSubscribersAPI();
      const formattedData = Object.values(data) as number[];
      return formattedData;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const getTotalCampaignSalesFromSubscribers = createAsyncThunk(
  "dashboard/getTotalCampaignSalesFromSubscribers",
  async () => {
    try {
      const { data } = await getTotalCampaignSalesFromSubscribersAPI();
      const formattedData = Object.values(data) as number[];
      return formattedData;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const getTotalCampaignProfitFromSubscribers = createAsyncThunk(
  "dashboard/getTotalCampaignProfitFromSubscribers",
  async () => {
    try {
      const { data } = await getTotalCampaignProfitFromSubscribersAPI();
      const formattedData = Object.values(data) as number[];
      return formattedData;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);
