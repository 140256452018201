import { AgencySliceType } from "@/types";
import { createSlice } from "@reduxjs/toolkit";
import { getAgencies } from "./thunk";

const initialState: AgencySliceType = {
  agencyList: [],
};

export const agencySlice = createSlice({
  name: "agency",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAgencies.fulfilled, (state, { payload }) => {
      state.agencyList = payload;
    });
  },
});

export const agencyReducer = agencySlice.reducer;
