import { useAppDispatch } from "@/app";
import { BreadCrumb, TableContainer } from "@/components";
import { exportRedemptionCodes, getRedemptionCodes } from "@/features";
import { RedemptionCodeType } from "@/types";
import { ColumnDef } from "@tanstack/react-table";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

const exportColumns = [
  {
    label: "Code",
    value: "code",
  },
  {
    label: "Created At",
    value: "created_at",
  },
];

const ViewRedemptionManagement = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<RedemptionCodeType[]>([]);
  const [defaultData, setDefaultData] = useState<RedemptionCodeType[]>([]);
  const [filter, setFilter] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const columns = useMemo<ColumnDef<RedemptionCodeType>[]>(
    () => [
      {
        header: "Code",
        accessorKey: "code",
        disableFilters: true,
        enableColumnFilter: false,
      },
      {
        header: "Created At",
        accessorKey: "created_at",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => moment(row.original.created_at).format("DD/MM/YYYY"),
      },
      {
        header: "Status",
        accessorKey: "status",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <div>
              {row.original.status?.toLowerCase() === "active" ? (
                <Badge color="success" style={{ fontSize: 13 }}>
                  {row.original.status}
                </Badge>
              ) : (
                <Badge color="danger" style={{ fontSize: 13 }}>
                  {row.original.status}
                </Badge>
              )}
            </div>
          );
        },
      },
      {
        header: "Exchange Date",
        accessorKey: "updated_at",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => moment(row.original.updated_at).format("DD/MM/YYYY"),
      },
      {
        header: "Customer Name",
        accessorKey: "customer_name",
        disableFilters: true,
        enableColumnFilter: false,
      },
      {
        header: "Customer Email",
        accessorKey: "customer_email",
        disableFilters: true,
        enableColumnFilter: false,
      },
    ],
    []
  );

  const onApply = () => {
    let filteredData = defaultData;

    if (filter.trim()) {
      filteredData = filteredData.filter(
        (item: RedemptionCodeType) =>
          item.user_email?.toLowerCase().includes(filter.toLowerCase()) ||
          item.user_name?.toLowerCase().includes(filter.toLowerCase())
      );
    }
    setData(filteredData);
  };

  const onReset = () => {
    setFilter("");
    setData(defaultData);
  };

  const onExport = async () => {
    setIsSubmitting(true);
    try {
      const data = await dispatch(exportRedemptionCodes(Number(id))).unwrap();
      let csv = convertArrayOfObjectsToCSV(data);
      if (csv == null) return;

      const filename = "Export_Redemeption_Code.csv";

      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
      }

      let link = document.createElement("a");
      link.setAttribute("href", encodeURI(csv));
      link.setAttribute("download", filename);
      link.click();
    } catch (error: any) {
      console.log("error: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const convertArrayOfObjectsToCSV = (array: RedemptionCodeType[]) => {
    let result = "";

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    result += exportColumns.map((d) => d.label).join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item: any) => {
      let ctr = 0;
      exportColumns.forEach((column) => {
        if (ctr > 0) result += columnDelimiter;

        let key = column.value;
        let cellValue = "";

        let keySplits = key.split(".");
        keySplits.forEach((subKey: any) => {
          cellValue = cellValue ? cellValue[subKey] : item[subKey];
        });

        result += '"' + cellValue + '"';
        ctr++;
      });
      result += lineDelimiter;
    });
    return result;
  };

  useEffect(() => {
    dispatch(getRedemptionCodes(Number(id)))
      .unwrap()
      .then((res) => {
        setData(res);
        setDefaultData(res);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }, [dispatch, id]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Codes" />
          <Card>
            <CardHeader>
              <Row>
                <Col xs={12} lg={3}>
                  <FormGroup>
                    <Label>Search</Label>
                    <div className="form-icon right">
                      <Input
                        placeholder="Search by name or email"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                      <i className="ri-search-line" />
                    </div>
                  </FormGroup>
                </Col>
                <Col xs={12} lg={3} className="mt-auto">
                  <FormGroup>
                    <Button color="primary" onClick={onApply}>
                      Apply
                    </Button>
                    <Button color="light" className="ms-2" onClick={onReset}>
                      Reset
                    </Button>
                  </FormGroup>
                </Col>
                <Col xs={12} lg={6} className="mt-auto">
                  <FormGroup className="d-flex flex-row justify-content-lg-end">
                    <Button
                      color="success"
                      onClick={() => onExport()}
                      disabled={isSubmitting}
                    >
                      <span>
                        <i className="ri-file-excel-2-line me-2" />
                      </span>
                      Export to CSV
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              <h4 className="card-title mb-0 mt-3">Code</h4>
            </CardHeader>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                customPageSize={10}
                tableClass="table-centered align-middle table-nowrap mb-0"
                theadClass="text-muted table-light"
                divClass="table-responsive"
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default ViewRedemptionManagement;
