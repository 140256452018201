import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuData } from "./constants";

export const MenuList = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(pathname);

  const resizeSidebarMenu = useCallback(() => {
    var windowSize = document.documentElement.clientWidth;

    const humberIcon = document.querySelector(".hamburger-icon") as HTMLElement;
    var hamburgerIcon = document.querySelector(".hamburger-icon");

    if (windowSize >= 1025) {
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
      if (document.documentElement.getAttribute("data-layout") === "semibox") {
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
      if (document.querySelector(".hamburger-icon")) {
        if (hamburgerIcon !== null) {
          hamburgerIcon.classList.remove("open");
        }
      } else {
        // var hamburgerIcon = document.querySelector(".hamburger-icon");
        if (hamburgerIcon !== null) {
          hamburgerIcon.classList.add("open");
        }
      }
    } else if (windowSize < 1025 && windowSize > 767) {
      document.body.classList.remove("twocolumn-panel");
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        document.documentElement.setAttribute("data-sidebar-size", "sm");
      }
      if (document.documentElement.getAttribute("data-layout") === "semibox") {
        document.documentElement.setAttribute("data-sidebar-size", "sm");
      }
      if (humberIcon) {
        humberIcon.classList.add("open");
      }
    } else if (windowSize <= 767) {
      document.body.classList.remove("vertical-sidebar-enable");
      if (
        document.documentElement.getAttribute("data-layout") !== "horizontal"
      ) {
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
      if (humberIcon) {
        humberIcon.classList.add("open");
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", resizeSidebarMenu, true);
  }, [resizeSidebarMenu]);

  useEffect(() => {
    const pathName = location.pathname.split("/");
    setActiveTab(`/${pathName[1]}`);
  }, [location]);

  return (
    <Fragment>
      {MenuData?.map((item: any) => {
        return (
          <li
            key={item.id}
            className={`nav-item px-2 py-1 ${
              activeTab === item.link ? "bg-info" : "bg-primary"
            }`}
            onClick={() => setActiveTab(item.link)}
          >
            <Link className="nav-link menu-link px-2 text-white" to={item.link}>
              <i className={item.icon}></i>{" "}
              <span className="text-truncate">{item.label}</span>
            </Link>
          </li>
        );
      })}
    </Fragment>
  );
};
