import { AuthSliceType } from "@/types";
import { createSlice } from "@reduxjs/toolkit";
import { login } from "./thunk";

const initialState: AuthSliceType = {
  token: localStorage.getItem("token") || null,
  isCheckingToken: true,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    checkIsLoggedIn: (state) => {
      const token = localStorage.get("token") || null;
      if (!token) {
        state.token = null;
        state.isCheckingToken = false;
        return;
      }
      state.token = token;
      state.isCheckingToken = false;
    },
    logOut: (state) => {
      state.token = null;
      localStorage.removeItem("token");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.token = payload;
      localStorage.setItem("token", payload);
    });
  },
});

export const authActions = {
  ...authSlice.actions,
};

export const authReducer = authSlice.reducer;
