import { Api } from "@/networking";
import {
  CreateRedemptionFormType,
  RedemptionCodeType,
  RedemptionType,
} from "@/types";

export const getRedemptionsAPI = () => {
  return Api.GET_REDMEPTIONS<RedemptionType[]>("");
};

export const getRedemptionDetailsAPI = (id: number) => {
  return Api.GET_REDEMPTION_DETAILS<RedemptionType>("", {
    urlParams: {
      id,
    },
  });
};

export const createRedemptionAPI = (data: CreateRedemptionFormType) => {
  return Api.CREATE_REDEMPTION<{}>("", {
    data,
  });
};

export const updateRedemptionAPI = (data: CreateRedemptionFormType) => {
  const { id, ...rest } = data;

  return Api.UPDATE_REDEMPTION<{}>("", {
    urlParams: {
      id,
    },
    data: rest,
  });
};

export const deleteRedemptionAPI = (id: number) => {
  return Api.DELETE_REDEMPTION<{}>("", {
    urlParams: {
      id,
    },
  });
};

export const getRedemptionCodesAPI = (id: number) => {
  return Api.GET_REDEMPTION_CODES<RedemptionCodeType[]>("", {
    urlParams: {
      id,
    },
  });
};

export const exportRedemptionCodesAPI = (id: number) => {
  return Api.EXPORT_REDEMPTION_CODES<RedemptionCodeType[]>("", {
    urlParams: {
      id,
    },
  });
};
