import {
  createRedemptionAPI,
  deleteRedemptionAPI,
  exportRedemptionCodesAPI,
  getRedemptionCodesAPI,
  getRedemptionDetailsAPI,
  getRedemptionsAPI,
  updateRedemptionAPI,
} from "@/services";
import { CreateRedemptionFormType } from "@/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";

export const getRedemptions = createAsyncThunk(
  "redemption/getRedemptions",
  async () => {
    try {
      const { data } = await getRedemptionsAPI();
      const formatData = data.map((dt) => {
        return {
          ...dt,
          created_at: dt.created_at ? moment(dt.created_at) : undefined,
          end_date: dt.end_date ? moment(dt.end_date) : undefined,
        };
      });
      return formatData;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const getRedemptionDetails = createAsyncThunk(
  "redemption/getRedemptionDetails",
  async (id: number) => {
    try {
      const { data } = await getRedemptionDetailsAPI(id);
      return data;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const createRedemption = createAsyncThunk(
  "redemption/createRedemption",
  async (data: CreateRedemptionFormType) => {
    try {
      await createRedemptionAPI(data);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const updateRedemption = createAsyncThunk(
  "redemption/updateRedemption",
  async (data: CreateRedemptionFormType) => {
    try {
      await updateRedemptionAPI(data);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const deleteRedemption = createAsyncThunk(
  "redemption/deleteRedemption",
  async (id: number) => {
    try {
      await deleteRedemptionAPI(id);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const getRedemptionCodes = createAsyncThunk(
  "redemption/getRedemptionCodes",
  async (id: number) => {
    try {
      const { data } = await getRedemptionCodesAPI(id);
      return data;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const exportRedemptionCodes = createAsyncThunk(
  "redemption/exportRedemptionCodes",
  async (id: number) => {
    try {
      const { data } = await exportRedemptionCodesAPI(id);
      return data;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);
