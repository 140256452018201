import { SubscriptionSliceType } from "@/types";
import { createSlice } from "@reduxjs/toolkit";
import { getSubscriptions } from "./thunk";

const initialState: SubscriptionSliceType = {
  subscriptionList: [],
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSubscriptions.fulfilled, (state, { payload }) => {
      state.subscriptionList = payload;
    });
  },
});

export const subscriptionReducer = subscriptionSlice.reducer;
