import logoSm from "@/assets/images/logo-sm.png";
import logo from "@/assets/images/logo.png";
import { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { MenuList } from "./MenuList";

export const Sidebar = () => {
  const addEventListenerOnSmHoverMenu = () => {
    if (
      document.documentElement.getAttribute("data-sidebar-size") === "sm-hover"
    ) {
      document.documentElement.setAttribute(
        "data-sidebar-size",
        "sm-hover-active"
      );
    } else if (
      document.documentElement.getAttribute("data-sidebar-size") ===
      "sm-hover-active"
    ) {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    } else {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    }
  };

  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
  });

  return (
    <Fragment>
      <div
        className="app-menu navbar-menu bg-primary"
        style={{ borderRight: "none" }}
      >
        <Link to="/" className="logo">
          <span className="logo-lg">
            <img src={logo} alt="" height="70" width="100%" />
          </span>
        </Link>
        <div className="navbar-brand-box">
          <Link to="/" className="logo">
            <span className="logo-sm">
              <img src={logoSm} alt="" height="22" />
            </span>
            {/* <span className="logo-lg">
              <img src={logo} alt="" height="66" width="230" />
            </span> */}
          </Link>

          <button
            onClick={addEventListenerOnSmHoverMenu}
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>
        <SimpleBar id="scrollbar" className="h-100">
          <ul className="navbar-nav w-100" id="navbar-nav">
            <MenuList />
          </ul>
        </SimpleBar>
      </div>
      <div className="vertical-overlay"></div>
    </Fragment>
  );
};
