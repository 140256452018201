import { useAppSelector } from "@/app";
import { PATH_NAME } from "@/constants";
import { ReportCampaignResult, ReportDashboard } from "@/pages";
import { Navigate, Route, Routes } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import AuthProtected from "./authProtected";

const Router = () => {
  const { token } = useAppSelector((state) => state.auth);

  return (
    <Routes>
      {publicRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            token ? (
              <Navigate to={PATH_NAME.DASHBOARD} replace />
            ) : (
              route.component
            )
          }
        />
      ))}
      <Route
        key={PATH_NAME.REPORT_DASHBOARD}
        element={<ReportDashboard />}
        path={PATH_NAME.REPORT_DASHBOARD}
      />
      <Route
        key={PATH_NAME.REPORT_CAMPAIGN_RESULT}
        element={<ReportCampaignResult />}
        path={PATH_NAME.REPORT_CAMPAIGN_RESULT}
      />
      <Route element={<AuthProtected />}>
        <Route path="*" element={<Navigate to={PATH_NAME.DASHBOARD} />} />
        {authProtectedRoutes.map((route) => (
          <Route key={route.path} element={route.component} path={route.path} />
        ))}
      </Route>
    </Routes>
  );
};

export default Router;
